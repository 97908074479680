import ListHeadingWithSearch from '../ListHeadingWithSearch/ListHeadingWithSearch';
import PaginationGroup from '../PaginationGroup/PaginationGroup';
import style from './MainContainerWithSearchAndPagination.module.css';

const MainContainerWithSearchAndPagination = ({
	children,
	activePage,
	numberOfPages,
	updatePageClicked,
	searchAction,
	heading,
}) => {
	return (
		<div className={style.main}>
			<ListHeadingWithSearch searchAction={searchAction}>
				{heading}
			</ListHeadingWithSearch>
			{children}
			<PaginationGroup
				activePage={activePage}
				numberOfPages={numberOfPages}
				updatePageClicked={updatePageClicked}
			/>
		</div>
	);
};

export default MainContainerWithSearchAndPagination;
