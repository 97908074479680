import React from 'react';
import { useNavigate } from 'react-router-dom';
import WhiteMainBackgroundWithBackButton from '../../../shared_components/WhiteMainBackgroundWithBackButton/WhiteMainBackgroundWithBackButton';
import SearchButton from '../../../shared_components/SearchButton/SearchButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import styles from './Tags.module.css';

function Tags() {
	const navigate = useNavigate();

	const handleSearch = () => {
		console.log('Searching for tags');
	};

	const handleBackButton = () => {
		navigate(-1);
	};

	const tableData = [
		{
			name: 'LO_G9_Dev_Self_Assess_Q09',
			tags: ['Sort', 'Words', '3 columns'],
			teamwork: 'https://ideaonline.teamwork.com/app/tasks/41674182',
		},
		{
			name: 'LO_G9_Dev_Self_Assess_Q08',
			tags: ['MCQ', 'Math', 'Font', 'Image'],
			teamwork: 'https://ideaonline.teamwork.com/app/tasks/41674182',
		},
		{
			name: 'LO_G9_Dev_Self_Assess_Q10',
			tags: ['Ordering', 'Text'],
			teamwork: 'https://ideaonline.teamwork.com/app/tasks/41674182',
		},
		{
			name: 'LO_G9_Dev_Self_Assess_Q11',
			tags: ['Single', 'Text', 'Input'],
			teamwork: 'https://ideaonline.teamwork.com/app/tasks/41674182',
		},
		{
			name: 'LO_G9_Dev_Self_Assess_Q03',
			tags: ['Sort', 'Images', '4 columns'],
			teamwork: 'https://ideaonline.teamwork.com/app/tasks/41674182',
		},
	];

	return (
		<WhiteMainBackgroundWithBackButton
			heading='Knowledge Center'
			backButtonAction={handleBackButton}>
			<div className={styles.container}>
				<p>Search a tag related to the artwork you are looking for</p>
				<SearchButton
					placeholder='Search for tags'
					searchAction={handleSearch}
				/>
				<hr />
				<table className={styles.table}>
					<thead>
						<tr>
							<th>Name</th>
							<th>Tags</th>
							<th>Teamwork</th>
							<th>Edit Tag</th>
						</tr>
					</thead>
					<tbody>
						{tableData.map((row, index) => (
							<tr key={index}>
								<td>{row.name}</td>
								<td>
									{row.tags.map((tag, tagIndex) => (
										<span key={tagIndex} className={styles.tag}>
											{tag}
										</span>
									))}
								</td>
								<td>
									<a
										href={row.teamwork}
										target='_blank'
										rel='noopener noreferrer'
										className={styles.teamworkLink}>
										{row.teamwork}
									</a>
								</td>
								<td className={styles.editButton}>
									<span aria-label='Edit'>
										<FontAwesomeIcon icon={faPencilAlt} />
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</WhiteMainBackgroundWithBackButton>
	);
}

export default Tags;
