import MenuStyle from './Menu.module.css';
import LoadingPulse from '../shared_components/LoadingPulse/LoadingPulse';
import MenuItem from './MenuItem/MenuItem';
import ENV from '../services/Environment';
import UserData from '../services/UserData';
import { useContext } from 'react';
import Permissions from '../services/Permissions';
import AIImageService from '../services/AIImageService';
import AIEssayService from '../services/AIEssayService';

const Menu = ({ backEndVersion }) => {
	const userData = useContext(UserData);

	const getVersion = () => {
		return backEndVersion === null ? (
			<LoadingPulse />
		) : (
			<span>v.{backEndVersion}</span>
		);
	};

	const versionNumber = getVersion();

	return (
		<div className={MenuStyle.menu}>
			<div className={MenuStyle.version}>
				<img src={ENV.appLogo} alt='Idea logo' />
				{versionNumber}
			</div>
			<MenuItem path='/plugins'>Plugins</MenuItem>
			<MenuItem path='/audio'>Audio</MenuItem>
			<MenuItem path='/glossary'>Plugin Glossary</MenuItem>
			{userData.roles.includes('admin') ? (
				<MenuItem path='/users/access-history'>Users</MenuItem>
			) : null}

			{Permissions.displayIfAnyOfTheRolesAreAllowed(
				userData,
				AIImageService.allowedRoles,
				<MenuItem path='/gallery'>AI Image Generator</MenuItem>
			)}

			{Permissions.displayIfAnyOfTheRolesAreAllowed(
				userData,
				AIEssayService.allowedRoles,
				<MenuItem path='/ai-essay'>AI Marking</MenuItem>
			)}

			{Permissions.displayIfAnyOfTheRolesAreAllowed(
				userData,
				AIImageService.allowedRoles,
				<MenuItem path='/search-wizard'>Search Wizard</MenuItem>
			)}

			{Permissions.displayIfAdmin(
				userData,
				<MenuItem path='/settings/version-control'>Settings</MenuItem>
			)}

			<MenuItem path='/documentation'>Documentation</MenuItem>
		</div>
	);
};

export default Menu;
