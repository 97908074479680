import { Folder, FileText } from 'lucide-react';
import styles from './FolderItem.module.css';

const FolderItem = ({ name, isDirectory, onClick }) => (
	<div
		className={styles.folderItem}
		onClick={onClick}
		role='button'
		tabIndex={0}>
		{isDirectory ? (
			<Folder className={styles.folderIcon} size={45} />
		) : (
			<FileText className={styles.fileIcon} size={45} />
		)}
		<span
			className={`${styles.folderName} ${
				isDirectory ? styles.folderText : styles.fileText
			}`}>
			{name}
		</span>
	</div>
);

export default FolderItem;
