import { useState } from 'react';
import styles from './SubjectCard.module.css';

const SubjectCard = ({ title, image, fontInfo, interactiveInfo, colors }) => {
	const [copiedColor, setCopiedColor] = useState(null);

	const handleColorClick = (color) => {
		navigator.clipboard.writeText(color).then(() => {
			setCopiedColor(color);
			setTimeout(() => setCopiedColor(null), 1500);
		});
	};

	return (
		<div className={styles.card}>
			<div className={styles.imageContainer}>
				<img
					src='https://imageplaceholder.net/900x200'
					alt={title}
					className={styles.image}
				/>
			</div>
			<h3 className={styles.cardTitle}>{title}</h3>
			<p className={styles.fontInfo}>{fontInfo}</p>
			<p className={styles.interactiveInfo}>{interactiveInfo}</p>
			<div className={styles.colorPalette}>
				<span className={styles.colorLabel}>Colours:</span>
				{colors.map((color, index) => (
					<div
						key={index}
						className={styles.colorSwatch}
						style={{ backgroundColor: color }}
						onClick={() => handleColorClick(color)}>
						{color === copiedColor && (
							<span className={styles.copiedText}>Copied!</span>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default SubjectCard;
