import { useNavigate } from 'react-router-dom';
import WhiteMainBackgroundWithBackButton from '../../../shared_components/WhiteMainBackgroundWithBackButton/WhiteMainBackgroundWithBackButton';
import SubjectCard from './SubjectCard/SubjectCard';
import styles from './DesignGuidelines.module.css';

function DesignGuidelines() {
	const navigate = useNavigate();

	const handleBackButton = () => {
		navigate(-1);
	};

	return (
		<WhiteMainBackgroundWithBackButton
			heading='Design Guidelines'
			backButtonAction={handleBackButton}>
			<div className={styles.container}>
				{/* <h1 className={styles.title}>Design Guidelines</h1> */}
				<div className={styles.cardContainer}>
					<SubjectCard
						title='English intro animation title'
						image='/path-to-english-image.jpg'
						fontInfo='Font: Open Sans'
						interactiveInfo='Interactive/Plugin Font size: Heading/30 pt subheading/25-28 pt'
						colors={['#f4511e', '#03a9f4', '#b3e5fc', '#e0e0e0']}
					/>
					<SubjectCard
						title='Science intro animation title'
						image='/path-to-science-image.jpg'
						fontInfo='Font: Open Sans'
						interactiveInfo='Interactive/Plugin Font size: Heading/30 pt subheading/25-28 pt'
						colors={['#fb8c00', '#03a9f4', '#4caf50', '#e0e0e0']}
					/>
					<SubjectCard
						title='Math intro animation title'
						image='/path-to-math-image.jpg'
						fontInfo='Font: Open Sans/ Times New Roman'
						interactiveInfo='Interactive/Plugin Font size: Heading/30 pt subheading/25-28 pt'
						colors={['#01579b', '#039be5', '#b3e5fc', '#e9fbff']}
					/>
				</div>
			</div>
		</WhiteMainBackgroundWithBackButton>
	);
}

export default DesignGuidelines;
