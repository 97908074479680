import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import FolderItem from './FolderItem/FolderItem';
import SearchWizardService from '../../../services/SearchWizardService';
import WhiteMainBackgroundWithBackButton from '../../../shared_components/WhiteMainBackgroundWithBackButton/WhiteMainBackgroundWithBackButton';
import styles from './KnowledgeCenter.module.css';
import UserData from '../../../services/UserData';
import Spinner from '../../../shared_components/Spinner/Spinner';

const KnowledgeCenter = () => {
	const navigate = useNavigate();
	const userData = useContext(UserData);
	const [folderContents, setFolderContents] = useState([]);
	const [currentFolder, setCurrentFolder] = useState('');
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const loadFolderContents = useCallback(
		async (folderPath = '') => {
			if (!userData) {
				setError('Authentication required');
				return;
			}

			setIsLoading(true);
			setError(null);
			try {
				if (!folderPath) {
					const structure = await SearchWizardService.getFolderStructure(
						userData.jwtToken,
						userData
					);
					if (structure && Array.isArray(structure)) {
						setFolderContents(structure);
						setBreadcrumbs([]);
						setCurrentFolder('');
					} else {
						throw new Error('Invalid response format');
					}
				} else {
					const result = await SearchWizardService.navigateToFolder(
						folderPath,
						userData.jwtToken,
						userData
					);
					if (result && result.contents) {
						setFolderContents(result.contents);
						setCurrentFolder(result.currentFolder);
						const newBreadcrumbs =
							SearchWizardService.getBreadcrumbPath(folderPath);
						setBreadcrumbs(newBreadcrumbs);
					} else {
						throw new Error('Invalid response format');
					}
				}
			} catch (err) {
				setError(err.message || 'Failed to load folder contents');
			} finally {
				setIsLoading(false);
			}
		},
		[userData]
	);

	useEffect(() => {
		if (userData) {
			loadFolderContents();
		}
	}, [loadFolderContents, userData]);

	const handleFolderClick = (folderName) => {
		const newPath = currentFolder
			? `${currentFolder}/${folderName}`
			: folderName;
		loadFolderContents(newPath);
	};

	const handleBreadcrumbClick = (path) => {
		loadFolderContents(path);
	};

	const handleBackButton = () => {
		if (breadcrumbs.length > 0) {
			const parentPath =
				breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2].path : '';
			loadFolderContents(parentPath);
		} else {
			navigate(-1);
		}
	};

	if (!userData) {
		return (
			<div className={styles.error}>
				Please log in to access the Knowledge Center
			</div>
		);
	}

	return (
		<WhiteMainBackgroundWithBackButton
			heading='Knowledge Center'
			backButtonAction={handleBackButton}>
			<div className={styles.container}>
				{breadcrumbs.length > 0 && (
					<div className={styles.breadcrumbs}>
						<span
							onClick={() => handleBreadcrumbClick('')}
							className={styles.breadcrumbItem}>
							Home
						</span>
						{breadcrumbs.map((crumb, index) => (
							<React.Fragment key={crumb.path}>
								<span className={styles.breadcrumbSeparator}>/</span>
								<span
									onClick={() => handleBreadcrumbClick(crumb.path)}
									className={`${styles.breadcrumbItem} ${
										crumb.isLast ? styles.breadcrumbActive : ''
									}`}>
									{crumb.name}
								</span>
							</React.Fragment>
						))}
					</div>
				)}

				{isLoading && (
					<div className={styles.loading}>
						<Spinner />
					</div>
				)}
				{error && <div className={styles.error}>{error}</div>}

				{!isLoading && (
					<div className={styles.folderList}>
						{folderContents.length === 0 && !isLoading && (
							<div className={styles.emptyState}>This folder is empty.</div>
						)}
						{folderContents.map((item, index) => (
							<FolderItem
								key={`${item.name}-${index}`}
								name={item.name}
								isDirectory={item.isDirectory}
								onClick={() => {
									if (item.isDirectory) {
										handleFolderClick(item.name);
									}
								}}
							/>
						))}
					</div>
				)}
			</div>
		</WhiteMainBackgroundWithBackButton>
	);
};

export default KnowledgeCenter;
