import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses, faCogs, faTags } from '@fortawesome/free-solid-svg-icons';
import styles from './SearchWizard.module.css';
import WhiteMainBackgroundWithHeading from '../../shared_components/WhiteMainBackgroundWithHeading/WhiteMainBackgroundWithHeading';
function SearchWizard() {
	const navigate = useNavigate();

	const handleCardClick = (path) => {
		navigate(path);
	};

	return (
		<WhiteMainBackgroundWithHeading heading='Search Wizard'>
			<div className={styles.container}>
				<div
					className={styles.card}
					onClick={() => handleCardClick('/knowledge-center')}>
					<div className={styles.icon}>
						<FontAwesomeIcon icon={faGlasses} />
					</div>
					<div className={styles.text}>Knowledge Center</div>
				</div>
				<div
					className={styles.card}
					onClick={() => handleCardClick('/design-guidelines')}>
					<div className={styles.icon}>
						<FontAwesomeIcon icon={faCogs} />
					</div>
					<div className={styles.text}>Design Guidelines</div>
				</div>
				<div className={styles.card} onClick={() => handleCardClick('/tags')}>
					<div className={styles.icon}>
						<FontAwesomeIcon icon={faTags} />
					</div>
					<div className={styles.text}>Tags</div>
				</div>
			</div>
		</WhiteMainBackgroundWithHeading>
	);
}

export default SearchWizard;
