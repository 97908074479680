import ENV from './Environment';
import Requests from './Requests';

const baseUrl = `${ENV.base_url}/api/v1/search_wizard`;
const listFilesUrl = `${baseUrl}/list-files/knowledge-center`;

const normalizePath = (path) => {
	if (!path) return '';
	return path.replace(/\/+/g, '/').trim();
};

const isValidFolderPath = (path) => {
	if (typeof path !== 'string') {
		return false;
	}

	const prohibitedChars = /[<>:"|?*]/;
	if (prohibitedChars.test(path)) {
		return false;
	}

	if (path.length > 1024) {
		return false;
	}

	return true;
};

const isValidJwtToken = (token) => {
	if (typeof token !== 'string' || token.trim() === '') {
		console.error('JWT token must be a non-empty string.');
		return false;
	}

	const parts = token.split('.');
	if (parts.length !== 3) {
		return false;
	}

	return true;
};

const listFolderContents = async (folderPath, jwtToken, userData) => {
	if (!isValidJwtToken(jwtToken)) {
		throw new Error('Invalid authentication token');
	}

	if (!isValidFolderPath(folderPath)) {
		throw new Error('Invalid folder path');
	}

	try {
		const normalizedFolderPath = normalizePath(folderPath);

		const url = normalizedFolderPath
			? `${listFilesUrl}?folder=${encodeURIComponent(normalizedFolderPath)}`
			: listFilesUrl;

		const result = await Requests.getRequest(url, jwtToken, userData);

		if (!result) {
			throw new Error('No response received from server');
		}

		if (!result.ok) {
			const errorData = await result.json();
			throw new Error(errorData.message || 'Unable to fetch folder contents');
		}

		const data = await result.json();
		if (!data || !data.s3FileAndDirectoryListList) {
			throw new Error('Invalid response format from server');
		}

		return data;
	} catch (error) {
		throw error;
	}
};

const getFolderStructure = async (jwtToken, userData) => {
	if (!jwtToken) {
		throw new Error('Authentication token is required');
	}

	try {
		const rootContents = await listFolderContents('', jwtToken, userData);
		if (!rootContents || !rootContents.s3FileAndDirectoryListList) {
			throw new Error('Invalid folder structure response');
		}

		return rootContents.s3FileAndDirectoryListList.map((item) => ({
			name: item.filename.replace(/^\/|\/$/g, ''),
			isDirectory: item.directory,
		}));
	} catch (error) {
		throw error;
	}
};

const navigateToFolder = async (folderPath, jwtToken, userData) => {
	if (!jwtToken) {
		throw new Error('Authentication token is required');
	}

	try {
		const normalizedFolderPath = normalizePath(folderPath);

		const contents = await listFolderContents(
			normalizedFolderPath,
			jwtToken,
			userData
		);

		if (!contents || !contents.s3FileAndDirectoryListList) {
			throw new Error('Invalid folder contents response');
		}

		const processedContents = contents.s3FileAndDirectoryListList
			.filter((item) => item.filename && item.filename.trim())
			.map((item) => {
				const trimmedFilename = item.directory
					? item.filename.replace(/\/$/, '')
					: item.filename;

				const relativePath = trimmedFilename.startsWith(folderPath + '/')
					? trimmedFilename.slice((folderPath + '/').length)
					: trimmedFilename;

				return {
					name: relativePath.split('/').pop() || trimmedFilename,
					isDirectory: item.directory,
					fullPath: item.filename,
					fileType: item.directory
						? null
						: item.filename.split('.').pop().toLowerCase(),
					originalItem: { ...item },
				};
			});

		return {
			currentFolder: normalizedFolderPath,
			contents: processedContents,
			_rawBackendResponse: contents,
		};
	} catch (error) {
		throw error;
	}
};

const searchInFolder = async (folderName, searchTerm, jwtToken, userData) => {
	if (!jwtToken) {
		throw new Error('Authentication token is required');
	}

	try {
		const normalizedFolderName = normalizePath(folderName);

		const url = `${listFilesUrl}?folder=${encodeURIComponent(
			normalizedFolderName
		)}&searchText=${encodeURIComponent(searchTerm)}`;

		const result = await Requests.getRequest(url, jwtToken, userData);

		if (!result) {
			throw new Error('No response received from server');
		}

		if (!result.ok) {
			throw new Error('Unable to search in folder');
		}

		const data = await result.json();
		if (!data || !data.s3FileAndDirectoryListList) {
			throw new Error('Invalid search response format');
		}

		return {
			currentFolder: data.currentFolder,
			results: data.s3FileAndDirectoryListList.map((item) => {
				const relativePath = item.filename.startsWith(folderName + '/')
					? item.filename.slice((folderName + '/').length)
					: item.filename;

				return {
					name: relativePath.replace(/^\/|\/$/g, ''),
					isDirectory: item.directory,
					fullPath: item.filename,
					fileType: item.filename.split('.').pop().toLowerCase(),
				};
			}),
		};
	} catch (error) {
		throw error;
	}
};

const getBreadcrumbPath = (folderPath) => {
	if (!folderPath) return [];

	const parts = folderPath.split('/').filter((part) => part);
	return parts.map((part, index) => ({
		name: part,
		path: parts.slice(0, index + 1).join('/'),
		isLast: index === parts.length - 1,
	}));
};

const SearchWizardService = {
	listFolderContents,
	getFolderStructure,
	navigateToFolder,
	searchInFolder,
	getBreadcrumbPath,
};

export default SearchWizardService;
